import React from "react"
import '../../assets/style.css';
import {  Container} from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import ReactReadMoreReadLess from "react-read-more-read-less";

const CxApproach=() => {

    const settings = {
        slidesPerView: 1.2,
        centeredSlides: true,
        spaceBetween: 20,
        // loop: true,
        noSwiping: false,
        breakpoints: {
          567: {
            centeredSlides: false,
            slidesPerView: 2.5,
            spaceBetween: 30,
            // loop: true
          },
          1200: {
            centeredSlides: false,
            pagination: false,
            spaceBetween: 35,
            slidesPerView: 3.5,
            noSwiping: false,
            noSwipingClass:"swiper-wrapper",
          }
        }
      };

    const te = [
        {title:"Storeez Studio", description:"Customer-focused experience design is the cornerstone of Storeez Studio’s work. Always with long-term vision and commercial impact in mind, we create digital products and user experiences that impact people for good.", imgUrl: "https://assets.sogody.co.uk/sogody/revamp/cx-1.png" },
        {title:"Engagement",description:"Whether you’re just getting started with UX or looking to refresh your existing processes and methodologies, we can help you create a fascinating experience for all your customers, increasing engagement and driving your business forward.",imgUrl: "https://assets.sogody.co.uk/sogody/revamp/cx-2.png" }, 
        {title:"Human-centered",description:"Our blend of strategic thinking, user-centered design, and agile engineering help us identify pragmatic solutions and deliver the results you need.", imgUrl: "https://assets.sogody.co.uk/sogody/revamp/cx-3.png"},       
        {title:"Partnership",description:"From project kick-off to launch, we integrate seamlessly into any team or organization.", imgUrl: "https://assets.sogody.co.uk/sogody/revamp/cx-4.png"}, 
        {title:"Optimization",description:"UX Research, Data Analysis, Ideation and Customer Journey Mapping, all specifically designed to uncover the biggest opportunities for optimization and innovation. The perfect way to start or reinvigorate a programme, and fill your roadmap with data-driven ideas.", imgUrl: "https://assets.sogody.co.uk/sogody/revamp/cx-5.png"},       
    ]
    
    return (
        <div className="our-approach-cx">  
            <Container> 
                <div className="te-head">
                    <h1>Our approach</h1> 
                </div>
            <Swiper {...settings}>
            {te.map((t)=>{
                return <SwiperSlide>
                <div className="te-content">
                <div className="sv-desktop">
                        <h2 className="title">{t.title}</h2>
                        <p className="description">{t.description}</p>
                    </div>

                    <div className="sv-mobile">
                        <h2 className="title">{t.title}</h2>
                        <ReactReadMoreReadLess
                            charLimit={120}
                            readMoreText={"Read more"}
                            readLessText={"Hide"}
                        >
                        {t.description}
                        </ReactReadMoreReadLess>
                    </div>
                        <img src={t.imgUrl}></img>
                    </div> 
                    </SwiperSlide>
                })}
                </Swiper>
            </Container>
        </div>
    )
}

export default CxApproach;
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import '../assets/style.css';
import { Row, Container, Col, Figure, Button} from 'react-bootstrap';
import ContactUs from './ContactUs';

export default function() {
    const [modalShow, setModalShow] = React.useState(false);
    const data = useStaticQuery(query);
    const {allContentfulWhatWeOfferInCxDesignService:{
        nodes:wwo
    }} = data

    return (
        <div className="wwo-dcm">  
            <Container> 
            <Row>
                <div className="wwo-head">
                    <h2 className="wwo-title">What we offer</h2> 
                    <span className="wwo-desc">The complete solution for server-side experimentation</span>
                </div>

                {wwo.map((wwo)=>{
                return <>
                <Col xs={12} md={6} lg={6}>
                    <div className="wwo-content">
                    <p className="title">{wwo.title}</p>
                    <p className="description">{wwo.description.description}</p>
                    </div>
                </Col>

                </>
                })}
                <Col xs={12} md={12} lg={12} className="btn-column">
                    <Button className="primary-btn-s dcm-get-a-quote"  onClick={() => setModalShow(true)}>Get a quote</Button>
                </Col>
            </Row>
            <ContactUs  show={modalShow} onHide={() => setModalShow(false)} /> 
            </Container></div>
    )
}

export const query = graphql`
{
    allContentfulWhatWeOfferInCxDesignService(filter: {node_locale: {eq: "en-US"}}, sort: {fields: createdAt}) {
        nodes {
        description {
            description
        }
        title
        }
    }
}
`



import React, { useState } from "react"
import { Row, Col, Container,Button} from 'react-bootstrap';
import banner from '../assets/images/services/cx-design-banner.png';
import ContactUs from "./ContactUs";

import '../assets/style.css';
export default function() {

    const [modalShow, setModalShow] = useState(false);

    return (
        <div className="banner-dcm single-service-banner">  
            <Container> 
                <Row>
                    <Col xs={12} md={12} lg={6} className="banner">
                        <h1 className="name">CX & Design</h1>
                        <p className="paragraph"> Crafting excellent user-centred design solutions that reflect the overarching brand and deliver a great user experience, all while leveraging data-backed UX, UI, and CX design.</p>
                        <Button   
                            className="primary-btn-s contact-us-btn"
                            onClick={() => setModalShow(true)} 
                        > Contact us </Button>
                    </Col>
                    <Col xs={12} md={12} lg={6} className="banner-img"><img src={banner} alt="cx design banner" /></Col>
                </Row> 
                <ContactUs  show={modalShow} onHide={() => setModalShow(false)} />
            </Container>  
        </div>
    )
}



import React from 'react'
import Layout from "../../components/Layout"
import FAQDCM from '../../components/FAQDCM'
import Updates from '../../components/Updates'
import CaseStudy from '../../components/CaseStudy'
import WWODCM from '../../components/WWODCM'
import DCMBoxUnder from "../../components/DCMBoxUnder";
import BannerDCM from '../../components/BannerDCM'
import { Seo } from '../../components/Seo'
// import myImg from '../../public/cx-og.png'
import Storeez from '../../components/Storeez'
import CxApproach from '../../components/our-approach/CxApproach'
import { useLocation } from "@reach/router"

export default function() {
    const pathLocation = useLocation();
    const url = pathLocation.href;
    return (
    <Layout>
        {/* image={'https://sogody.com'+myImg} */}
        <Seo 
            title="CX & Design - Sogody" 
            description="We design human-centered experiences by digitising processes, services, transactions, websites and mobile apps in order to help your brand deliver next-level digital experiences." 
            url={url}
        />
        <div className="data-management-page">  
        <BannerDCM/>
        {/* <DCMBoxUnder/> */}
        {/* <DCMBox/> */}
        <WWODCM/>
        <CxApproach/>
        <Storeez/>
        <CaseStudy/>
        {/* <FAQDCM/> */}
        <Updates/>
        </div>
    </Layout>
    )
}


